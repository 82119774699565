import React from 'react'
import Layout from '../components/ui-components/containers/layout'
import TextContainer from '../components/ui-components/containers/text-container'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import ProjectStep from '../components/project/project-step'
import Smile from '../components/ui-components/smile/smile'
import Transparency from '../components/project/transparency'
import SEO from '../components/seo/SEO'

const Projekt = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "project" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const steps = [
    {
      text:
        'Über einen festgelegten Zeitraum hinweg freuen wir uns, Ihre Spenden entgegenzunehmen. Natürlich sind Sie auch darüber hinaus herzlich eingeladen, für unser Projekt zu spenden - zur besseren Planung und Kalkulation bestimmen wir jedoch jedes Jahr einen Stichtag; anhand der bis zu diesem Tag eingenommenen Summe sehen wir, mit welchem Budget wir arbeiten können. In diesem Jahr sammeln wir ausschließlich über unsere betterplace.me-Kampagne (Link setzen).',
      left: false,
    },
    {
      text:
        'Wir arbeiten bundesweit mit zahlreichen Kindertafeln zusammen. Diese versorgen uns mit den Informationen, die wir zur erfolgreichen Umsetzung des Projektes benötigen. Das heißt: wie viele Kinder gehen regelmäßig zur jeweiligen Kindertafel? Wie viele Kinder werden an dem Projekt teilnehmen? Nur mit diesen Informationen können wir vernünftig kalkulieren, um so – mit Ihrer Hilfe – jedem Kind ein Lächeln ins Gesicht zu zaubern.',
      left: true,
    },
    {
      text:
        'Im zweiten Schritt geben die Kindertafeln die von uns vorbereiteten Briefe an die Eltern der Kinder weiter, in denen wir unser Projekt vorstellen und die Eltern zur Teilnahme einladen. Hier können sie den Wunsch ihrer Kinder auf einem Wunschzettel vermerken oder den von ihren Kindern geschriebenen Wunschzettel beifügen',
      left: false,
    },
    {
      text:
        'Die ausgefüllten Briefe schicken die Tafeln daraufhin jeweils gesammelt an uns zurück. Sobald wir alle Briefe erhalten haben, kümmern wir uns um die Besorgung der Geschenke und bereiten die Geschenkeausgabe vor. Die Geschenkeausgabe an die Eltern erfolgt rechtzeitig vor Weihnachten; wir möchten, dass diese ihre Kinder am Weihnachtsabend selber beschenken können. ',
      left: true,
    },
  ]

  return (
    <Layout>
      <SEO title={'Über unser Projekt'} />
      <div className="mt-12">
        <TextContainer>
          <Transparency></Transparency>

          <div className="mt-4 md:mt-24">
            {data.allFile.edges.slice(1).map((edge: any, i: number) => (
              <ProjectStep imgLeft={steps[i].left} key={i}>
                <div
                  className={`w-5/6 md:w-1/4 flex items-center pb-4 md:pb-0 ${
                    steps[i].left ? 'md:justify-start' : 'md:justify-end'
                  } justify-center`}
                  key={i}
                >
                  <Img
                    fluid={edge.node.childImageSharp.fluid}
                    alt=""
                    className="w-40 h-40 rounded-lg"
                  />
                </div>
                <div className="w-5/6 md:w-3/4">{steps[i].text}</div>
              </ProjectStep>
            ))}

            <div className="flex w-full h-32 justify-center items-center hidden md:block">
              <svg viewBox="0 0 1000 200" height="100%">
                <path
                  d="M 500 200 Q 450 100 250 100 Q 100 100 100 0"
                  stroke="#fec402"
                  strokeWidth="5"
                  fill="transparent"
                />
              </svg>
            </div>

            <div className="w-full md:w-1/2 m-auto shadow-lg bg-gray-100 flex items-center justify-center flex-col pt-12 border-brand border-2 rounded-lg">
              <div className="h-32 w-32">
                <Smile></Smile>
              </div>
              <p className="w-full p-4 text-center ">
                Am 24.12. haben in ganz Deutschland unzählige Kinder ein
                <strong> Leuchten in den Augen</strong>, weil
                <strong> Sie</strong> Ihnen ein wundervolles Weihnachtsfest
                ermöglicht haben.
              </p>
            </div>

            <div className="text-sm pb-12 pt-24">
              <hr className="border-1 border-brand pb-4" />
              *In diesem Jahr werden wir leider nur in ausgewählten Tafeln eine
              Geschenkeausgabe durchführen können; das hängt damit zusammen,
              dass wir leider noch nicht die Anzahl an freiwilligen Helfern
              stellen können, um bundesweit Geschenke zu besorgen und an der
              jeweiligen Kindertafel zu sammeln. Deshalb werden wir in manchen
              Tafeln Ihre Spenden – natürlich fair aufgeteilt und weihnachtlich
              verpackt – rechtzeitig vor Weihnachten an die Eltern übergeben,
              die sich dann selbst um die Besorgung der Geschenke kümmern. Mit
              Ihrer Hilfe wollen wir jedoch so wachsen, dass es uns in Zukunft
              immer möglich sein wird, mit einer Schar an Weihnachtselfen selbst
              die Geschenkebesorgung und -ausgabe durchführen zu können.
            </div>
          </div>
        </TextContainer>
      </div>
    </Layout>
  )
}

export default Projekt
