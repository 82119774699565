import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useSpring, animated } from 'react-spring'

interface ProjectStepInterface {
  imgLeft: boolean
  children: React.ReactNode
}

const ProjectStep = (props: ProjectStepInterface) => {
  const imgName = { ...props }

  const [anim, toggle] = useState(false)
  const { strokeDashoffset } = useSpring({
    reverse: anim,
    from: { strokeDashoffset: 1500 },
    to: { strokeDashoffset: 0 },
    config: { duration: 2000 },
  })

  useEffect(_ => {
    toggle()
  }, [])

  return (
    <div>
      <div className="flex w-full justify-center items-center hidden md:block">
        <animated.svg
          viewBox="0 0 1000 200"
          width="100%"
          transform={props.imgLeft ? 'scale(1, 1)' : 'scale(1, -1)'}
        >
          <animated.path
            d="M 900 0 Q 900 100 500 100 Q 100 50 100 200 "
            stroke="#fec402"
            strokeWidth="5"
            fill="transparent"
          />
        </animated.svg>
      </div>
      <div
        className={`flex items-center justify-between flex-col pb-12 md:pb-0  ${
          props.imgLeft ? 'md:flex-row' : 'md:flex-row-reverse'
        } `}
      >
        {props.children}
      </div>
    </div>
  )
}

export default ProjectStep
