import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const Transparency = ({}) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "project/00_Transparenz.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex justify-center items-center flex-col">
        <h2 className="font-handwritten text-3xl text-center">
          Mit Ihrer Hilfe bringen wir Kinderaugen zum Leuchten!
        </h2>
        <p className="text-xl mt-4 text-center max-w-lg">
          Wir möchten, dass Sie genau wissen, wie Ihre Spende eingesetzt wird
          und wie sie letztendlich hilft.
        </p>{' '}
      </div>

      <div className="h-full flex items-center mt-12 justify-center hidden md:block">
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt=""
          className="w-24 h-24"
        />
      </div>

      <div className="h-32 mb-4 pt-4 block md:hidden flex items-center justify-center mb-24">
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt=""
          className="w-24 h-24"
        />
      </div>
    </div>
  )
}
Transparency.propTypes = {}

export default Transparency
